import { graphql } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import BannerWithOutBg from "../components/common/BannerWithOutBg"
import Paginator from "../components/common/Paginator"
import SEORevamp from "../components/common/SEO_Revamp"
import EbookCard from "../components/e-books/EbookCard"
import MainLayout from "../layouts/MainLayout"

const EbookTemplate = ({ data, pageContext }) => {
  // const [eBooksData, setEbooksData] = useState([])
  // const [searching, setSearching] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const ebooks = data.allWpEBooks.nodes
  const eBooksData = data.allStrapiEbook.nodes
  const { totalPages, currentPage } = pageContext

  // useEffect(() => {
  //   filterResult("")
  // }, [ebooks])

  // const filterResult = searchTerm => {
  //   if (searchTerm === "") {
  //     setSearching(false)
  //     setEbooksData(ebooks)
  //   } else {
  //     getSearchedData(searchTerm)
  //   }
  // }

  // const getSearchedData = searchTerm => {
  //   setLoading(true)
  //   const url = `https://${process.env.GATSBY_API_BASE_URL}/wp-json/wp/v2/posts?_embed&postper_page=100&page=1&search=${searchTerm}`
  //   try {
  //     fetch(url, {
  //       method: "GET",
  //     })
  //       .then(res => res.json())
  //       .then(data => {
  //         let arr = []
  //         arr =
  //           data?.length > 0 &&
  //           data.map(item => {
  //             return {
  //               authorName:
  //                 (item?._embedded?.author[0] &&
  //                   item?._embedded?.author[0]?.name) ||
  //                 "",
  //               image: item?._embedded["wp:featuredmedia"]["0"].source_url,
  //               title: (
  //                 <p
  //                   dangerouslySetInnerHTML={{ __html: item.title.rendered }}
  //                 ></p>
  //               ),
  //               slug: item?.slug,
  //               date: item?.date,
  //               readingTime: item?.seo?.readingTime,
  //               category: item?._embedded["wp:term"]["0"].name,
  //             }
  //           })
  //         setSearching(true)
  //         setEbooksData(arr)
  //         setLoading(false)
  //       })
  //   } catch (err) {
  //     setLoading(false)
  //   }
  // }

  return (
    <MainLayout bgChanged={false}>
      <BannerWithOutBg
        heading="eBooks"
        subHeading="Download top stories, Stay updated with trend."
      />

      {/* <Container> */}
      {/* <CategoryStrip
          categories={categories}
          search={filterResult}
          mainPage={true}
          type={"e-book"}
          bookisTrue={true}
        /> */}
      {/* </Container> */}
      <section>
        <Container>
          <Row>
            {eBooksData?.length > 0 ? (
              eBooksData.map((ebook, i) => (
                <Col md={6} lg={4} xl={4} key={i} style={{ padding: 15 }}>
                  <EbookCard ebook={ebook} />
                </Col>
              ))
            ) : (
              <Col className="text-center">No Data</Col>
            )}
          </Row>
          {/* {!searching && !loading && ( */}
          <Row className="mt-5">
            <Col className="d-flex justify-content-center">
              <Paginator
                slug="ebook"
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
          {/* )} */}
        </Container>
      </section>
    </MainLayout>
  )
}

export const query = graphql`
  query getEbooksAndCategories($skip: Int!, $limit: Int!) {
    allStrapiEbook(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: publishedDate }
    ) {
      nodes {
        slug
        title
        readingTime
        publishedDate(formatString: "MMMM DD, YYYY")
        category {
          name
        }
        heroImg {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default EbookTemplate

export const Head = () => {
  return (
    <SEORevamp
      title="E-Books | InvoZone"
      description="Free e-books by a world-class software development team. Read ebooks well written by InvoZone’s experts covering different emerging technologies."
    />
  )
}
