import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Card from "react-bootstrap/Card"
import * as styles from "./EbookCard.module.scss"

const EbookCard = ({ ebook }) => {
  const { title, slug, publishedDate, readingTime, heroImg, category } = ebook // prettier-ignore
  const image = getImage(heroImg?.localFile)

  return (
    <Card className={styles.card}>
      <Link to={`/e-books/${slug}/`}>
        <GatsbyImage
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          image={image}
          alt={title}
          style={{ height: 187 }}
        />
      </Link>
      <Card.Body className={styles.containerA}>
        <Link to={`/e-books/${slug}/`}>
          <div className={styles.categoryName}>
            <small>{category?.name}</small>
          </div>
          <Card.Text className={styles.title}>{title}</Card.Text>
          <div className={styles.date}>
            <small>
              {" "}
              {publishedDate} | {readingTime || 0} minutes read{" "}
            </small>
          </div>
        </Link>
      </Card.Body>
    </Card>
  )
}

export default EbookCard
